body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: bold;
  font-size: 3rem;
}

h2 {
  font-weight: bold;
  font-size: 1.8rem;
}

h3 {
  font-weight: bold;
  font-size: 1.1rem;
}

a.underline {
  text-decoration: underline !important;
}
a.underline:hover {
  text-decoration: none !important;
}

.login-logo {
  margin-top: 3rem;
}
.login-logo h1,
h2 {
  font-weight: normal;
  line-height: 2.8rem;
}

iframe {
  display: inline-block;
}

.login-logo img {
  display: inline-block;
}

.mobile-nav {
  background: #180b23;
  z-index: 2;
  position: relative;
}
